import React, { Component } from 'react';
import classNames from 'classnames';
import BasePage from 'components/base-page-mobile';
import Tag from 'components/tag';
import { PoweredBy } from 'components/poweredby';
import PreviewImage from 'components/preview-image';
import RichText from 'components/rich-text';
import { ariaRole } from 'components/util/aria';
import {  getProductId, getContentId } from 'components/util';
import Icon from 'components/icon';
import {
  get_api_teamblog as getApiTeamblog,
  get_api_teamblog_read as getApiTeamblogRead,
  post_api_v2_teamblog_like as postApiV2TeamblogLike,
  post_api_v2_teamblog_liked as postApiV2TeamblogLiked,
  post_api_v2_teamblog_unlike as postApiV2TeamblogUnlike,
} from 'components/api';
import { replaceAnchorHref } from 'components/render-rich-text';
import { TcIconWarning } from 'components/IconSystem';

import 'components/skeleton/style.less';
import 'components/css/mobile/reset.less';
import './style.less';


export class App extends Component {
  constructor(props) {
    super(props);

    this.onClickLike = this.onClickLike.bind(this);
    this.state = {
      isLike: false,
      author_avatar: '',
      author_name: '',
      content: '',
      date: '',
      like_count: 0,
      read_count: 0,
      title: '',
      previewError: false,
    };
    this.productId = getProductId();
    this.blogId = getContentId();
  }

  componentDidMount() {
    const { productId, blogId } = this;


    getApiTeamblog({ productId, blogId })
      .then((resp) => {
        this.setState(resp.data);
        return resp.data;
      })
      .then(this.loadDataSuccess)
      .catch(() => this.setState({ previewError: true }));

    getApiTeamblogRead({ productId, blogId });

    postApiV2TeamblogLiked({ productId, ids: [blogId] }).then((resp) => {
      if (!resp.data || !resp.data[blogId]) {
        return;
      }

      const { liked } = resp.data[blogId];

      this.setState({ isLike: liked });
    });
  }

  // 数据加载完后的一些操作，目前只有小程序用到
  loadDataSuccess() {}

  onClickLike() {
    const { state, productId, blogId } = this;

    const { isLike } = state;

    if (!isLike) {
      postApiV2TeamblogLike({ productId, blogId });
      this.setState({
        isLike: true,
        like_count: state.like_count + 1,
      });
    } else {
      postApiV2TeamblogUnlike({ productId, blogId });
      this.setState({
        isLike: false,
        like_count: state.like_count - 1,
      });
    }
  }

  render() {
    const { state } = this;

    return (
      <BasePage >
        {!state.previewError && (
        <Tag.div className="page_blog">

          <Tag.div className="container">

            <Tag.h3 className="article-title skeleton-block auto-focus">{state.title}</Tag.h3>

            <Tag.div
              className="article-meta"
              aria-label={`${state.author_name} ${state.date}`}
                            >

              <Tag.div className="author-avatar" aria-hidden={true}>
                <Tag.img src={state.author_avatar} mode="widthFix" className="author-avatar__profile" />
              </Tag.div>

              <Tag.div className="author-name skeleton-inline">{state.author_name}</Tag.div>

              <Tag.span className="skeleton-inline">{state.date}</Tag.span>

            </Tag.div>

            <Tag.hr aria-hidden={true} />

            <Tag.div className="article-content skeleton-multi" >
              <PreviewImage content={replaceAnchorHref(state.content)}>
                <RichText previewImg className="article-content skeleton-multi" content={replaceAnchorHref(state.content)} />
              </PreviewImage>
            </Tag.div>

            <Tag.div className="footer">

              <Tag.div className="meta">

                <Tag.div className="meta-read">
                  {`${state.read_count}人阅读`}
                </Tag.div>

                <Tag.div
                  {...ariaRole('button')}
                  className="meta-like"
                  onClick={this.onClickLike}
                  aria-label={`${state.like_count}赞  ${state.isLike ? '已点赞' : ''}`}
                                    >
                  <Tag.span isText className={classNames('meta-like-num', { active: state.isLike })} >{state.like_count}</Tag.span>
                  {state.isLike ? <Icon className="tcIcon_liked" type="liked-dark" /> : <Icon className="tcIcon_likes" type="likes-light" />}
                </Tag.div>

              </Tag.div>

            </Tag.div>

          </Tag.div>
          {/* <GoHome /> */}
          <PoweredBy />
        </Tag.div>
        )}

        {state.previewError && (
        <Tag.div className="error-tip--container">
          <TcIconWarning />
          <Tag.h6 className="error-tip__h6">没有权限</Tag.h6>
          <Tag.p className="error-tip__p">非管理员无法查看预览模式</Tag.p>
        </Tag.div>
        )}
      </BasePage>
    );
  }
}
